import { Observable } from "rxjs";
import { RespInterface, RespListInterface } from "@common/ports/interfaces";

import { ProductServiceInterface } from "@products-services/ports/interfaces";

export abstract class ProductServiceAbstraction {
  abstract list(query: string): Observable<RespListInterface<ProductServiceInterface>>;
  abstract create(productService: ProductServiceInterface): Observable<RespInterface<ProductServiceInterface>>;
  abstract update(productService: ProductServiceInterface): Observable<RespInterface<string>>;
  abstract read(id: string): Observable<RespInterface<ProductServiceInterface>>;
  abstract delete(id: string): Observable<RespInterface<ProductServiceInterface>>;
}
